
import { defineComponent } from 'vue';
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'

export default defineComponent({
  name: 'Convert',
  components: { 
    Editor
  },
  data() {
    return {
      message: 'Hello!',
      src: null,
      schema: "",
      language: "",
      roman_schemas: [
        { id: "iast", name: "IAST" },
        { id: "itrans", name: "ITRANS" },
        { id: "hk", name: "Harvard-Kyoto" },
        { id: "slp1", name: "SLP1" },
        { id: "velthuis", name: "Velthuis" },
        { id: "wx", name: "WX" }
      ],
      languages: [
        {id:"gujarati",name:"Gujarati"},
        {id:"hindi",name:"Hindi"},
        {id:"hindi",name:"Sanskrit"}
      ],
      results:null
    }
  },
  methods: {
    wordMapper: function (src: any, lipi: any) {
        let regex = /(<([^>]+)>)/ig;
        src = src.replace(regex, "").split(" ");
        lipi = lipi.replace(regex, "").split(" ");

        let words = [];

        for (let i = 0; i < src.length; i++) {
            var wordObject = {
              src:null,
              lipi:null,
              order:0
            };
            wordObject.src = src[i];
            wordObject.lipi = lipi[i];
            wordObject.order = i;
            words.push(wordObject);
        }
        return words;
    },

    convert() {
      var data = {
          src_lang: this.language,
          text: this.src,
          roman_schema: this.schema
      };
      var uri = process.env.VUE_APP_API_URI ? process.env.VUE_APP_API_URI : '/api';

      axios.post(`${uri}/translate`, data).then(res => {
        this.results=res.data;
        // $('#nav-slipi-tab li:last-child a').tab('show'); // eslint-disable-next-line
      })
    }
  },  
  computed: {}
});
