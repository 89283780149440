/* eslint-disable */
import jQuery from "jquery";

function enableTypeWriter(selector, language) {
    var uri = process.env.VUE_APP_QUILL_URI ? process.env.VUE_APP_QUILL_URI : '';
    jQuery(selector).off().removeData();
    // https://github.com/devbridge/jQuery-Autocomplete
    jQuery(selector).autocomplete({
        autoSelectFirst: true,
        delimiter: " ",
        serviceUrl: `${uri}/quill/processWordJSON`,
        dataType: "json",
        tabDisabled: true,
        paramName: 'inString',
        params: {
            lang: language
        },
        transformResult: function(response) {
            return {
                suggestions: jQuery.map(response.twords[0].options, function(dataItem) {
                    return dataItem;
                })
            };
        },
        onSelect: function (suggestion) {
            console.log('You selected: ' + suggestion.value + ', ' + suggestion.data);
        },
        onHint: function(s) {
            console.log(s);
        }
    });
}

export {
    enableTypeWriter
}