
import { defineComponent } from 'vue';
import axios from 'axios'
import { useMeta } from 'vue-meta'
import Editor from '@tinymce/tinymce-vue'
require('@/assets/js/devbridge/jquery.autocomplete.js')
import {enableTypeWriter} from '@/assets/js/typer.js'
import jQuery from "jquery";

export default defineComponent({
    name: 'Write',
    components: { 
        Editor
    },
    setup() {
        useMeta({
            title: 'Write in any indic language'
        })
    },
    data() {
        return {
            suggestions: [],
            languages: {
                gujarati: {short_code: "gu" },
                hindi: {short_code: "hi" },
                sanksrit: {short_code: "sa" }
            },
            selectedLanguage: null,
            results:null,
            inputValue: null,
            taValue: null,
        }
    },
    mounted() { 
        if (localStorage.selectedLanguage) {
            this.selectedLanguage = localStorage.name;
        }
        jQuery(function() {
            enableTypeWriter('.autocomplete', localStorage.selectedLanguage);
        })
    },
    methods: {
        handleOpen () {
            console.log("opened.")
        },
        handleClose () {
            console.log("closed.")
        },
        setLanguage(event: any) {
            console.log(`i just set language to: ${event.target.value}`)
            localStorage.selectedLanguage = event.target.value;
            // enableTypeWriter('.autocomplete', event.target.value);
            enableTypeWriter('.autocomplete', localStorage.selectedLanguage);
        },
        getSuggestions(text:string) {
            var uri = "https://stage.translate.swaminarayan.faith/quill/processWordJSON";
            let lang = "gujarati";
            axios.get(`${uri}`, {params: {inString: text, lang: lang }}).then((response: any) => {
                this.suggestions=response.twords[0].options;
            })
        }
    },  
    computed: {}
});
