
/* eslint-disable */

import { defineComponent, ref } from 'vue';
import { useMeta } from 'vue-meta'
import Editor from '@tinymce/tinymce-vue'
import preProcessImage from '@/assets/js/ocr/preprocess';
require('p5');
// import jQuery from "jquery";
import Tesseract from 'tesseract.js';

declare const window: any;

export default defineComponent({
    name: 'OCR',
    components: {
        Editor
    },
    setup() {
        useMeta({
            title: 'OCR Image'
        });
    },
    data() {
        return {
            imageUrl:null,
            hocr:null,
            text:null,
            confidence:null,
            selectedLanguage:"",
            supportedLanguages: [
                { name: "English", code: "eng" },
                { name: "Gujarati", code: "guj" },
                { name: "Hindi", code: "hin" },
                { name: "Sanskrit", code: "san" }
            ],
            logger:null,
            mediaSupported: false,
            constraints: {
                video: {'facingMode':'environment',width: {min: 1280}, height: {min: 720}}
            },
            // worker: Tesseract.createWorker({
            //     logger: m => this.logger = m
            // }),
        }
    },
    mounted() {
    },
    methods: {
        hasGetUserMedia() {
            return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
        },
        enableVideo() {
            // let video = document.getElementById('videoCamera') as HTMLVideoElement;
            let video = this.$refs.videoCamera as HTMLVideoElement;
            if (this.hasGetUserMedia()) {
                navigator.mediaDevices.getUserMedia(this.constraints).then(function (stream) {
                    window.stream = stream;
                    video.srcObject = stream;
                })
                .catch(function (err) {
                    console.log("Something went wrong!"+err);
                });
            }
        },
        snapShot() {
            let img = this.$refs.videoImage as HTMLImageElement;
            let video = this.$refs.videoCamera as HTMLVideoElement;
            let canvas = this.$refs.videoCanvas as HTMLCanvasElement;

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(video, 0, 0);
            ctx?.putImageData(preProcessImage(canvas),0,0);
            img.src = canvas.toDataURL('image/png');
            this.ocrImageUrl(img.src);
        },

        ocrImageFile(imageFile: any) {
            let img = imageFile.target.files[0];
            this.ocr(img, this.selectedLanguage);
        },

        ocrImageUrl(url: any) {
            this.ocr(url, 'eng');
        },

        ocr(url:any, language:string) {
            Tesseract.recognize(url, language, { 
                logger: m => {
                    this.logger = m
                }
            }).then((result:any) => {
                this.confidence = result.data.confidence;
                this.text = result.data.text;
                this.hocr = result.data.hocr;
            });
        },
    },  
    computed: {}
});
